
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}


.navbar-brand img,
.footer-logo{
  max-height: 20px;
}

.alert{
 p{
  color: inherit;
  margin-bottom: 0.2rem;
  }
  a{
color: inherit!important;
  font-weight: bold;
}
}
.rd-preview{
  position: relative;
  width: 100%;
  background-color: rgba(255,255,255,0.6);

  canvas{
    max-width: 100%;
    background-color: #fff;
    z-index: 99;
    position: relative;
  }
  .demo{
    z-index: 0;
    position: absolute;
    left: 0;
right: 0;
top: 0;
bottom: 0;
    background-image: url('../img/factuur_demo.jpg');
    background-size: cover;
    opacity: 0.3;
  }

  .rd-loader{
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dragQR{
  z-index: 999;
  cursor: move;
  display: inline-block;
  position:absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  font-family: 'Arial';

  .info{
    color: #000;
  }
  .title{
    font-size: 1em;
    display: block;
    line-height: 1em;
  }
  .amount{
    font-size: 1.4em;
    line-height: 1.4em;
  }
  .reference{
    font-size: 0.8em;
    line-height: 0.8em;
  }

  .titlewrapper{
    padding-bottom: 0.2em;
  }

  .sidewrapper{
    display: flex;
    flex-direction: column;
  }

  &.single{
    .info{
      display: none;
    }
  }

  &.right{
    .sidewrapper,
    .qrwrapper{
      float: left;
    }
    .sidewrapper{
      padding-left: 0.5em;
    }
  }

  &.bottom{
    .title{
      display: flex;
      flex-direction: column;
    }
    .qrwrapper,
    .info{
      text-align:center;
    }
  }

}

.circle-btn{
  border-radius: 100%;
  background-color: rgba(255,255,255, 0.7);
  color: #000;
  width: 15px;
  height: 15px;
  text-align: center;
  display: inline-block;
  line-height: 15px;
  font-size: 10px;

}

.toggleInvoice,
.removeInvoice{
  cursor: pointer;
  &:hover{
    text-decration: underline;
  }
}

//dropzone basic
.dropzone, .rd-dropzone * {
  box-sizing: border-box
}

.dz-preview,
.dz-file-preview {
  display:none
}

.dropzone, .rd-dropzone * {
  box-sizing: border-box
}

.rd-dropzone {
  border: 5px dashed rgba(255, 255, 255, 0.3);
  margin-bottom: 2em;
  padding: 2em;
  &.dz-drag-hover {
    border-style: solid;
    border-color: #fff;
  }
}

.rd-dropzone.dz-clickable {
  cursor: pointer
}

.rd-dropzone.dz-clickable * {
  cursor: default
}

.rd-dropzone.dz-clickable .dz-message,
.rd-dropzone.dz-clickable .dz-message * {
  cursor: pointer
}

.rd-dropzone.dz-started .dz-message {
  display: none
}



.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $blue;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
